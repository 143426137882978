<template>
    <div class="flex items-center justify-between">
        <h2 :class="['vut-h4', titleClass]">
            <slot>
                {{ title }}
            </slot>
        </h2>

        <UiButtonClose @click="injected?.close" />
    </div>
</template>

<script lang="ts" setup>

const {
    title,
    titleClass,
} = defineProps<{
    title?: string
    titleClass?: string
}>()

const { injected } = useBaseModalProvide()

</script>

<style lang="scss" scoped>

</style>
